@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Ubuntu:wght@400;500;700&display=swap);
:root {
  --wheel-font: 'Open Sans', sans-serif;
  --wheel-font-size: 18px;
  --wheel-size: 290px;
  --wheel-slice-spacing: 50px;
  --wheel-border-size: 10px;
  --wheel-color: #1fc4d0;
  --neutral-color: white;
  --PI: 3.14159265358979;
  --nb-item: 1e-99;
  --item-nb: 1e-99;
  --selected-item: 0;
  --nb-turn: 5;
  --spinning-duration: 4s;
  --reset-duration: 0.25s;
  --opacity: 100%;
  --logo_background: url("/static/media/wally logo.96ecaefd.png");
  --logo_background_size: 60px;
}

/* Conteneur de la roue = cercle rose extérieur */
.wheel-container {
  transform: rotate(-90deg);
  display: block;
  position: relative;
  box-sizing: content-box;
  width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  padding: 3px;
  margin: auto;
  background-color: var(--neutral-color);
  border: solid var(--wheel-color) 3px;
  border-radius: 50%;
  -webkit-user-select: none;
          user-select: none;
  margin-top: 3%;
}

/* Triangle de sélection = 1 rose +  1 blanc */
.wheel-container::before,
.wheel-container::after {
  content: "";
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  border: solid transparent 20px;
  border-left-width: 0;
}

.wheel-container::before {
  right: 0px;
  border-right-color: var(--wheel-color);
}

.wheel-container::after {
  right: -5px;
  border-right-color: var(--neutral-color);
}

/* Roue */
.wheel {
  display: block;
  position: relative;
  box-sizing: content-box;
  margin: auto;
  width: var(--wheel-size);
  height: var(--wheel-size);
  overflow: hidden;
  border-radius: 50%;
  border: solid var(--wheel-color) var(--wheel-border-size);
  background-color: var(--wheel-color);
  transition: transform var(--reset-duration);
  transform: rotate(0deg);
  justify-content: center;
  align-items: center;
}

.btn-warning.btn-orange {
  color: rgb(0, 0, 0);
  background-color: #e7d106;
  border-color: #e7d106;
  position: relative;
  z-index: 2;
  font-family: "ubuntu";
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 20px;
  font-weight: 505;
  cursor: pointer;
}

.btnAfterClick {
  color: rgb(0, 0, 0);
  background-color: #e7d106;
  border-color: #e7d106;
  position: relative;
  z-index: 2;
  font-family: "ubuntu";
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 20px;
  font-weight: 505;
  opacity: 60%;
}

.wheel.spinning {
  transition: transform var(--spinning-duration);
  transform: rotate(
    calc(
      var(--nb-turn) * 360deg +
        (-360deg * var(--selected-item) / var(--nb-item, 1))
    )
  );
}

/* Centre de la roue = rond blanc au centre */
.wheel::after {
  display: block;
  position: absolute;
  content: "";
  background-image: var(--logo_background);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px;
  overflow: hidden;
  width: 150px;
  height: 170px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

/* Element sur la roue */
.wheel-item {
  display: block;
  position: absolute;
  box-sizing: border-box;

  /* position de l'item */
  top: 50%;
  left: 50%;
  width: 50%;
  transform-origin: center left;
  transform: translateY(-50%)
    rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

  /* texte */
  color: var(--neutral-color);
  text-align: right;
  padding: 0 25px 0 50px;
  font-family: var(--wheel-font);
  font-size: var(--wheel-font-size);
  
}

/* Background de l'élément = triangle rose plus clair */
.wheel-item:before {
  content: " ";
  display: block;
  position: absolute;
  box-sizing: border-box;
  z-index: -1;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 0px;
  opacity: 0.25;

  /* 
    Largeur du triangle
    Je ne sais pas pourquoi j'ai besoin d'ajouter 
      " + var(--wheel-size) / 2 " dans slice-max-width  ==> donne 2.PI.R + R
    mais globalement ca marche a peu près bien 
    TODO: A étudier 
  */
  --slice-max-width: calc(
    var(--PI) * var(--wheel-size) + var(--wheel-size) / 2
  );
  --slice-width: calc(
    (var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing)
  );
  border: solid transparent calc(var(--slice-width) / 2);
  border-left: solid transparent 0;
  /* profondeur du triangle = du centre de la roue à la bordure = 300px / 2 */
  border-right: solid var(--neutral-color) calc(var(--wheel-size) / 2);
}
.wheel-background {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  /* background: url('../../assets/img/1712455.jpg') no-repeat center; */
  background-size: cover;
  margin: auto;
}

.textmodal {
  font-family: "ubuntu";
  font-size: small;
  color: #000000a6;
}

.inputModal {
  margin-left: 11px;
}

.swal-modal {
  background: aliceblue !important;
  border-radius: 5% !important;
}

.swal-text{
  
  font-family: "ubuntu";
}

.btnRetirar {
  padding-top: 35px;
}
@media screen and (min-width: 540px) {
  .wheel-background {
    width: 100vh;
    width: auto;
  }
  .wheel-container {
    margin-top: 17vh;
  }
  .RouletteText {
    font-size: xx-large;
    text-align: center;
    color: white;
    font-family: 'ubuntu'
  }
  .RouletteText2 {
    font-size: xx-large;
    text-align: center;
    color: rgb(36, 34, 34);
    font-family: 'ubuntu';
    font-weight: 600;
  }
  .wheel {
    display: block;
    position: relative;
    box-sizing: content-box;
    margin: auto;
    width: 400px;
    height: 400px;
    overflow: hidden;
    border-radius: 50%;
    border: solid var(--wheel-color) var(--wheel-border-size);
    background-color: var(--wheel-color);
    transition: transform var(--reset-duration);
    transform: rotate(0deg);
    justify-content: center;
    align-items: center;
  }
  /* Conteneur de la roue = cercle rose extérieur */
  .wheel-container {
    transform: rotate(-90deg);
    display: block;
    position: relative;
    box-sizing: content-box;
    width: calc(400px + 2 * var(--wheel-border-size));
    height: calc(400px + 2 * var(--wheel-border-size));
    padding: 3px;
    margin: auto;
    background-color: var(--neutral-color);
    border: solid var(--wheel-color) 3px;
    border-radius: 50%;
    -webkit-user-select: none;
            user-select: none;
    margin-top: 3%;
  }

  /* Triangle de sélection = 1 rose +  1 blanc */
  .wheel-container::before,
  .wheel-container::after {
    content: "";
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border: solid transparent 20px;
    border-left-width: 0;
  }

  .wheel-container::before {
    right: 0px;
    border-right-color: var(--wheel-color);
  }

  .wheel-container::after {
    right: -5px;
    border-right-color: var(--neutral-color);
  }

  /* Roue */
  .wheel {
    display: block;
    position: relative;
    box-sizing: content-box;
    margin: auto;
    width: 400px;
    height: 400px;
    overflow: hidden;
    border-radius: 50%;
    border: solid var(--wheel-color) var(--wheel-border-size);
    background-color: var(--wheel-color);
    transition: transform var(--reset-duration);
    transform: rotate(0deg);
    justify-content: center;
    align-items: center;
  }

  .btn1 {
    color: rgb(0, 0, 0);
    background-color: #e7d106;
    border-color: #e7d106;
    position: relative;
    z-index: 2;
    font-family: "ubuntu";
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 20px;
    font-weight: 505;
    cursor: pointer;
  }

  .btnAfterClick1 {
    color: rgb(0, 0, 0);
    background-color: #e7d106;
    border-color: #e7d106;
    position: relative;
    z-index: 2;
    font-family: "ubuntu";
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 20px;
    font-weight: 505;
    opacity: 60%;
  }

  .wheel.spinning {
    transition: transform var(--spinning-duration);
    transform: rotate(
      calc(
        var(--nb-turn) * 360deg +
          (-360deg * var(--selected-item) / var(--nb-item, 1))
      )
    );
  }

  /* Centre de la roue = rond blanc au centre */
  .wheel::after {
    display: block;
    position: absolute;
    content: "";
    background-image: var(--logo_background);
    /* background-image: url("../../assets/img/wally\ logo.png"); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: var(--logo_background_size);
    overflow: hidden;
    width: 150px;
    height: 170px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  /* Element sur la roue */
  .wheel-item {
    display: block;
    position: absolute;
    box-sizing: border-box;

    /* position de l'item */
    top: 50%;
    left: 50%;
    width: 50%;
    transform-origin: center left;
    transform: translateY(-50%)
      rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

    /* texte */
    color: var(--neutral-color);
    text-align: right;
    padding: 0 25px 0 50px;
    font-family: var(--wheel-font);
  }

  /* Background de l'élément = triangle rose plus clair */
  .wheel-item:before {
    content: " ";
    display: block;
    position: absolute;
    box-sizing: border-box;
    z-index: -1;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 0px;
    opacity: 0.25;

    /* 
    Largeur du triangle
    Je ne sais pas pourquoi j'ai besoin d'ajouter 
      " + var(--wheel-size) / 2 " dans slice-max-width  ==> donne 2.PI.R + R
    mais globalement ca marche a peu près bien 
    TODO: A étudier 
  */
    --slice-max-width: calc(var(--PI) * 400px + 400px / 2);
    --slice-width: calc(
      (var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing)
    );
    border: solid transparent calc(var(--slice-width) / 2);
    border-left: solid transparent 0;
    /* profondeur du triangle = du centre de la roue à la bordure = 300px / 2 */
    border-right: solid var(--neutral-color) calc(400px / 2);
  }
  .wheel-background {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    /* background: url('../../assets/img/1712455.jpg') no-repeat center; */
    background-size: cover;
    margin: auto;
  }
}
@media screen and (max-width: 320px) {
  .wheel-background {
    width: 100vh;
    width: auto;
  }
  .wheel-container {
    margin-top: 17vh;
  }
  .RouletteText {
    font-size: xx-large;
    text-align: center;
    color: white;
    font-family: "ubuntu";
  }
  .RouletteText2 {
    font-size: xx-large;
    text-align: center;
    color: rgb(36, 34, 34);
    font-family: "ubuntu";
    font-weight: 600;
  }
  .wheel {
    display: block;
    position: relative;
    box-sizing: content-box;
    margin: auto;
    width: 240px;
    height: 240px;
    overflow: hidden;
    border-radius: 50%;
    border: solid var(--wheel-color) var(--wheel-border-size);
    background-color: var(--wheel-color);
    transition: transform var(--reset-duration);
    transform: rotate(0deg);
    justify-content: center;
    align-items: center;
  }
  /* Conteneur de la roue = cercle rose extérieur */
  .wheel-container {
    transform: rotate(-90deg);
    display: block;
    position: relative;
    box-sizing: content-box;
    width: calc(240px + 2 * var(--wheel-border-size));
    height: calc(240px + 2 * var(--wheel-border-size));
    padding: 3px;
    margin: auto;
    background-color: var(--neutral-color);
    border: solid var(--wheel-color) 3px;
    border-radius: 50%;
    -webkit-user-select: none;
            user-select: none;
    margin-top: 3%;
  }
  /* Triangle de sélection = 1 rose +  1 blanc */
  .wheel-container::before,
  .wheel-container::after {
    content: "";
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border: solid transparent 20px;
    border-left-width: 0;
  }
  .wheel-container::before {
    right: 0px;
    border-right-color: var(--wheel-color);
  }
  .wheel-container::after {
    right: -5px;
    border-right-color: var(--neutral-color);
  }
  /* Roue */
  .wheel {
    display: block;
    position: relative;
    box-sizing: content-box;
    margin: auto;
    width: 240px;
    height: 240px;
    overflow: hidden;
    border-radius: 50%;
    border: solid var(--wheel-color) var(--wheel-border-size);
    background-color: var(--wheel-color);
    transition: transform var(--reset-duration);
    transform: rotate(0deg);
    justify-content: center;
    align-items: center;
  }
  /* .btn-warning.btn-orange{
  color: rgb(0, 0, 0);
  background-color: #e7d106;
  border-color: #e7d106;
  position: relative;
  z-index: 2;
  font-family: 'Lato';
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 20px;
  font-weight: 505;
  cursor: pointer;
} */
  /* .btnAfterClick{
  color: rgb(0, 0, 0);
  background-color: #e7d106;
  border-color: #e7d106;
  position: relative;
  z-index: 2;
  font-family: 'Lato';
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 20px;
  font-weight: 505;
  opacity: 60%;
} */
  .wheel.spinning {
    transition: transform var(--spinning-duration);
    transform: rotate(
      calc(
        var(--nb-turn) * 360deg +
          (-360deg * var(--selected-item) / var(--nb-item, 1))
      )
    );
  }
  /* Centre de la roue = rond blanc au centre */
  .wheel::after {
    display: block;
    position: absolute;
    content: "";
    background-repeat: var(--logo_background);
    /* background-image: url("../../assets/img/wally\ logo.png"); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: var(--logo_background_size);
    overflow: hidden;
    width: 150px;
    height: 170px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  /* Element sur la roue */
  .wheel-item {
    display: block;
    position: absolute;
    box-sizing: border-box;

    /* position de l'item */
    top: 50%;
    left: 50%;
    width: 50%;
    transform-origin: center left;
    transform: translateY(-50%)
      rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

    /* texte */
    color: var(--neutral-color);
    text-align: right;
    padding: 0 25px 0 50px;
    font-family: var(--wheel-font);
  }
  /* Background de l'élément = triangle rose plus clair */
  .wheel-item:before {
    content: " ";
    display: block;
    position: absolute;
    box-sizing: border-box;
    z-index: -1;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 0px;
    opacity: 0.25;

    /* 
    Largeur du triangle
    Je ne sais pas pourquoi j'ai besoin d'ajouter 
      " + var(--wheel-size) / 2 " dans slice-max-width  ==> donne 2.PI.R + R
    mais globalement ca marche a peu près bien 
    TODO: A étudier 
  */
    --slice-max-width: calc(var(--PI) * 240px + 240px / 2);
    --slice-width: calc(
      (var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing)
    );
    border: solid transparent calc(var(--slice-width) / 2);
    border-left: solid transparent 0;
    /* profondeur du triangle = du centre de la roue à la bordure = 300px / 2 */
    border-right: solid var(--neutral-color) calc(240px / 2);
  }
  .wheel-background {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    /* background: url('../../assets/img/1712455.jpg') no-repeat center; */
    background-size: cover;
    margin: auto;
  }
}

.textmodal {
  font-family: "ubuntu";
  font-size: small;
  color: #000000a6;
}

.inputModal {
  margin-left: 11px;
}

.swal-modal {
  border-radius: 5% !important;
}

/* .btnRetirar{
  padding-top: 35px;
} */

.headermodal {
  background: linear-gradient(
    to bottom,
    rgba(41, 181, 246, 1) 0%,
    rgba(41, 181, 246, 1) 20%,
    rgba(29, 233, 182, 1) 83%,
    rgba(29, 233, 182, 1) 100%
  ) !important;
}

/* .swal-title {
  margin: 0px;
  margin-top: -10px;
  font-size: 16px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
  margin-bottom: 28px;
  background: linear-gradient(to right, rgba(41,181,246,1) 0%, rgba(41,181,246,1) 20%, rgba(29,233,182,1) 83%, rgba(29,233,182,1) 100%) !important; */

/* } */
/*Opcion 2*/
.swal-title {
  top: -26px;
  padding: 20px;
  border-top-right-radius: 5%;
  border-top-left-radius: 5%;
  /* border-top-right-radius: 17px;
  border-top-left-radius: 17px; */
  margin: 0px;
  margin-top: -10px;
  /* font-size: 16px; */
  box-shadow: 0px 1px 1px rgb(0 0 0 / 21%);
  margin-bottom: 28px;
  background: linear-gradient(
    to right,
    rgba(41, 181, 246, 1) 0%,
    rgba(41, 181, 246, 1) 20%,
    rgba(29, 233, 182, 1) 83%,
    rgba(29, 233, 182, 1) 100%
  ) !important;
  
  font-family: 'ubuntu';
}

.swal-title:not(:last-child) {
  margin-bottom: -13px;
}

.swal-footer {
  text-align: center;
}

.error_Text_Modal {
  display: none;
  color: red;
  font-size: 13px;
  margin-left: 100px;
  font-family: 'ubuntu';
}
#a {
  font-size: 13px;
}

.swal-icon--success__hide-corners {
  width: 0px;
  height: 0px;
  background-color: #fff;
  padding: 1px;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  transform: rotate(-45deg);
}

.swal-icon--success:after,
.swal-icon--success:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  width: 0px;
  height: 0px;
  background: #fff;
  transform: rotate(45deg);
}

.confetti_drop {
  display: none;
  z-index: 20;

  /* width: 100%;
  height: 100%;  */
  /* z-index: 2; */

  /* position: absolute; */
  /* margin-top: -300px;
  margin-left: -700px;
   */
  /* width:1000px;
  height:1000px; */
  /* opacity: 1; */
}
/* padding-left: -100px; */
/* block-size: 200vh; */
@media screen and (max-width: 540px) {
  .confetti_drop {
    position: absolute;
    display: inline;
    width: 100%;
    z-index: -1;

    /* background-color: rgba(0,0,0,0); */
  }
  .swal-overlay {
    background-color: rgba(0, 0, 0, 0);
  }
}

/* posicion segun altura */

/* iPhone 5/SE */
@media  screen and (max-height: 568px) {

  .RouletteText {
    margin-top: -4px;
    margin-bottom: -26px;

  }}
 
  /* Moto G4 */ /* Galaxy S5 */  /* iPhone 6/7/8 */
@media  screen and (max-height: 667px) { 

  .RouletteText {
    margin-top: -8px;
  }
  @media  screen and (max-width: 280px) {

    .wheel {
      width: 230px !important;
      height: 230px !important;
    }
    .wheel-container {
      width: calc(230px + 2 * var(--wheel-border-size));
      height: calc(230px + 2 * var(--wheel-border-size));
    }

    .wheel-item:before {
      --slice-max-width: calc(var(--PI) * 230px + 230px / 2);
      border-right: solid var(--neutral-color) calc(230px / 2);
    }
    @media  screen and (height: 653px) {

      .RouletteText {
        margin-top: 3vh;
    
      }}
  } 
}
/* Pixel 2 */ /* iPhone 6/7/8 Plus*/
@media  screen and (min-height:668px) and (max-height: 736px) {

  .RouletteText {
    
    padding-top: 0;

  }}

/* Pixel 2 XL */ /* iPhone X */
@media  screen and (min-height:737px) and (max-height: 823px) {

  .RouletteText {
    padding-top: 5vh;

  }}

  
@media  screen and (min-height:823px) and (max-height: 1023px) {

  .RouletteText {
    padding-top: 5vh;

  }}
/* iPad */
@media  screen and (min-height:1024px) and (max-height: 1365px) {

  .RouletteText {
    padding-top: 11vh;

  }}
/* iPad Pro */
@media  screen and (min-height:1366px)  {

  .RouletteText {
    padding-top: 19vh;

  }}
@media screen and (min-width: 1024px) and (max-width: 1330px)
{
  .wheel {
    width: 340px !important;
    height: 340px !important;
  }
  .wheel-container {
    width: calc(340px + 2 * var(--wheel-border-size));
    height: calc(340px + 2 * var(--wheel-border-size));
  }

  .wheel-item:before {
    --slice-max-width: calc(var(--PI) * 340px + 340px / 2);
    border-right: solid var(--neutral-color) calc(340px / 2);
  }
}
:root {
  --app-font: 'Quicksand', 'Lato', sans-serif;
  --neutral-color: white;
  
}

.App {
  text-align: center;
  font-family: var(--app-font);
  -webkit-user-select: none;
          user-select: none;
}
.RouletteText{
  font-size: xx-large;
  text-align: center;
  color: white;
  font-family: 'Ubuntu';
}
.RouletteText2 {
  font-size: xx-large;
  text-align: center;
  color: rgb(36, 34, 34);
  font-family: 'Ubuntu';
  font-weight: 600;
}
.content{
  color: white;
  width: auto;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.swal-overlay {
    position: absolute;
}
html, body {
  min-height: 100%;
  overflow: hidden;
  
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Ubuntu:wght@400;500;700&display=swap');


:root {
  --app-font: 'Quicksand', 'Lato', sans-serif;
  --neutral-color: white;
  
}

.App {
  text-align: center;
  font-family: var(--app-font);
  user-select: none;
}
.RouletteText{
  font-size: xx-large;
  text-align: center;
  color: white;
  font-family: 'Ubuntu';
}
.RouletteText2 {
  font-size: xx-large;
  text-align: center;
  color: rgb(36, 34, 34);
  font-family: 'Ubuntu';
  font-weight: 600;
}
.content{
  color: white;
  width: auto;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.swal-overlay {
    position: absolute;
}
html, body {
  min-height: 100%;
  overflow: hidden;
  
}